import React, { useState } from 'react'
import Layout from '../../components/layout/layout.component'
import Banner from '../../components/banner/banner.component'
//import indexStyles from './index-page.module.scss'

export default function Home() {
   const [isMutted, setIsMutted] = useState(false)
   const handleAudio = mutted => {
      console.log('muted', mutted)
      setIsMutted(mutted)
   }
   return (
      <Layout
         hasBackground
         isMenuWhite
         isMutted={isMutted}
         handleAudio={handleAudio}
      >
         <Banner isMutted={isMutted} handleAudio={handleAudio} />
      </Layout>
   )
}
