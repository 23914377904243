import React, { useEffect, useRef, useState } from 'react'
import bannerStyles from './banner.module.scss'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import BackgroundSlider from 'gatsby-image-background-slider'
import intro from '../../videos/intro.mp4'
import Arrow from '../arrow/arrow.component'

export default function Banner({ isMutted, handleAudio }) {
   //const [isMutted, setIsMutted] = useState(true)
   const [isPlaying, setIsPlaying] = useState(false)
   const video = useRef(null)
   console.log('isPlaying', isPlaying, isMutted)

   const data = useStaticQuery(graphql`
      query {
         backgrounds: allFile(
            filter: {
               extension: { regex: "/(jpg)|(jpeg)|(png)/" }
               relativeDirectory: { eq: "banner" }
            }
            limit: 4
         ) {
            totalCount
            nodes {
               dir
               size
               base
               childImageSharp {
                  fluid(maxWidth: 1440, quality: 50) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      }
   `)

   useEffect(() => {
      console.log(video.current)
      const timer = setInterval(() => {
         if (video.current && video.current.readyState === 4) {
            // it's loaded
            setIsPlaying(true)
            console.log('video is loaded adn clearInterval')
            clearInterval(timer)
         }
      }, 10)
      return () => clearInterval(timer)
   }, [isPlaying, video])

   const goLink = link => {
      navigate(link)
   }

   return (
      <div className={bannerStyles.container}>
         {
            //<div
            //tabIndex={0}
            //role='button'
            //className={bannerStyles.audioIcon}
            //onClick={toggleMutted}
            //onKeyDown={toggleMutted}
            //>
            //<img
            //src={isMutted ? SoundOff : SoundOn}
            //alt='sound'
            //className={bannerStyles.icon}
            ///>
            //</div>
         }
         <video
            ref={video}
            //style={{ display: isPlaying ? 'block' : 'none' }}
            style={{ display: isPlaying ? 'block' : 'none' }}
            onEnded={() => goLink('/studio')}
            preload='auto'
            autoPlay
            muted={!isMutted}
            onClick={() => handleAudio(!isMutted)}
            id={bannerStyles.video}
         >
            <source src={intro} type='video/mp4' />
            <track default kind='captions' srcLang='en' src='' />
            Your browser does not support the video tag.
         </video>

         <BackgroundSlider query={data}>
            <div className={bannerStyles.text}>
               <h2>“I paint to find out what painting is”</h2>
               <h3>Esteban Vicente</h3>
            </div>
            <div className={bannerStyles.text}>
               <h2>“If I don’t paint, I don’t live”</h2>
               <h3>Esteban Vicente</h3>
            </div>
            <div></div>
            <div></div>
         </BackgroundSlider>
         <Arrow to='/studio/workshop' />
      </div>
   )
}
